import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core'
import { provideRouter, withViewTransitions } from '@angular/router'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { provideAnimations } from '@angular/platform-browser/animations'

import { routes } from './app.routes'
import { provideHotToastConfig } from '@ngneat/hot-toast'
import { tokenInterceptor } from '@lib/interceptors/token.interceptor'
import { GlobalErrorHandler } from '@lib/handlers/global-error.handler'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { environment } from '@environments/environment'
import { getAuth, provideAuth } from '@angular/fire/auth'

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(routes, withViewTransitions()),
		provideHttpClient(withInterceptors([tokenInterceptor])),
		provideAnimations(),
		provideHotToastConfig(),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideAuth(() => getAuth()),
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
	],
}
