import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'
import { environment } from '@environments/environment'
import { from, mergeMap } from 'rxjs'
import { TokenService } from '@lib/services/token/token.service'

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
	return from(getToken()).pipe(
		mergeMap((token) => {
			// Si la URL de la petición contiene la URL de la API, se añade el token de autenticación
			// if (req.url.includes(environment.apiUrl)) {
			// 	const headers: HttpHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` })
			// 	// const reqClone = req.clone({ headers })
			// 	const reqClone = req.clone({ headers })

			// 	return next(reqClone)
			// }
			// temporal mientras se implementa endpoints del backend
			const headers: HttpHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` })
				// const reqClone = req.clone({ headers })
				const reqClone = req.clone({ headers })
				return next(reqClone)

			// Si la URL de la petición no contiene la URL de la API, se envía la petición sin el token de autenticación
			// return next(req)
		})
	)
}

// Obtiene el token de autenticación de OAuth o Amplify
const getToken = async (): Promise<string> => {
	const tokenService = inject(TokenService)

	if (tokenService.appStore.isUserSignedIn()) {
		return tokenService.appStore.userInfo()?.idToken ?? '';
	}
	return '';
	// // Verificamos si el usuario inició sesión con OAuth
	// if (oauthStorage.getItem('id_token')) {
	// 	return oauthStorage.getItem('id_token') ?? ''
	// }

	// // Verificamos si el usuario inició sesión con Amplify
	// const amplifyToken = (await fetchAuthSession()).tokens?.idToken?.toString()

	// return amplifyToken ?? ''
	return ''
}
