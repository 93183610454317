import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { ToastNotificationsPortalComponent } from './components/notifications/toast-notifications-portal/toast-notifications-portal.component'

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, ToastNotificationsPortalComponent],
	template: `
		<router-outlet />
		<app-toast-notifications-portal />
	`,
})
export class AppComponent {}
